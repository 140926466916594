<template>
  <div>
    <div class="container">
      <el-card style="margin-top: 20px">
        <div class="input-with">
          <el-button type="primary" @click="addArithmetic">新增匹配</el-button>
          <!-- <el-button >批量导入</el-button> -->
        </div>
        <!-- <div style="float: right">
            <div class="seach_box">
              <el-input
                placeholder="请输入名称"
                v-model="queryInfo.queryConditions"
                class="input-with-select"
              >
                <el-button class="seach" slot="append" @click="search()">搜索</el-button>
              </el-input>
            </div>
          </div> -->
        <el-table
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          @sort-change="changeSort"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column label="编号" align="center" prop="id"></el-table-column>

          <el-table-column label="算法名称" prop="arithmetic" align="center"></el-table-column>
          <el-table-column label="实体1" align="center" width="160">
            <template v-slot="{ row }">
              <span>{{ row.entityOne }}</span>
            </template>
          </el-table-column>

          <el-table-column label="实体2" width="160" prop="entityTwo" align="center">
            <template v-slot="{ row }">
              <span>{{ row.entityTwo }}</span>
            </template>
          </el-table-column>
          <el-table-column label="关联标签组" width="160" prop="content" align="center">
            <template v-slot="{ row }">
              <div v-if=" row.groupLabelTypes">
            <span v-for="(el,index) in  row.groupLabelTypes " :key="index">
            <span v-if="index<3">{{ el }}</span>
            </span>
                <el-tooltip effect="light" placement="bottom">
                  <div slot="content">
                    <div style="padding:5px" v-for="(el,index) in  row.groupLabelTypes " :key="index">
                      <span>{{el}}</span>
                    </div>
                  </div>
                  <el-button style="color:#333333" type="text" v-if="row.groupLabelTypes.length>3">....</el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="版本号" prop="versionNumber" align="center">
            <template v-slot="{ row }">
              <span v-if="row.versionNumber">{{ row.versionNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否启用" prop="createTime" align="center">
            <template v-slot="{ row }">
              <el-switch
                @change="notState(row)"
                v-model="row.isEnable"
                :active-text="row.isEnable ? '启用' : '禁用'"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="新增人" prop="createName" align="center">
            <template v-slot="{ row }">
              <span>{{ row.createName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="新增时间" sortable prop="createTime" align="center">
            <template v-slot="{ row }">
              <div>{{ row.createTime | date }}</div>
              <div>{{ row.createTime | time }}</div>
            </template>
          </el-table-column>
          <el-table-column label="更新人" prop="updateName" align="center">
            <template v-slot="{ row }">
              <span>{{ row.updateName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="更新时间" prop="updateTime" align="center">
            <template v-slot="{ row }">
              <div>{{ row.updateTime | date }}</div>
              <div>{{ row.updateTime | time }}</div>
            </template>
          </el-table-column>

          <el-table-column label="操作" prop="createTime" align="center">
            <template v-slot="{ row }">
              <span class="edit" @click="editClick(row)">编辑</span>
              <span v-if="!row.isFreeze" @click="accoutClick(row)" class="frozen">冻结</span>
              <span v-else @click="accoutClick(row)" class="frozen">激活</span>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>

    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {
  arithmeticList,
  insertArithmetic,
  selectById,
  updateArithmetic,
  updateEnable,
  updateFreeze
} from '@/api/matemanage'

const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  orderByParam: 'c.create_time desc'
})
export default {
  name: 'matemanage',
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: true,
      // 总条数
      total: 0
    }
  },
  created() {
    // this.search()
    if (this.$route.query.pageNum) {
      this.queryInfo.pageNum = Number(this.$route.query.pageNum)
      this.search()
    } else {
      this.queryInfo.pageNum = 1
      this.search()
    }
  },
  methods: {
    //查询一览数据
    async search() {
      this.loading = true
      const { data: res } = await arithmeticList(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.listData.forEach((el) => {
          if (el.groupLabelTypes) {
            el.groupLabelTypes = el.groupLabelTypes.split('、')
          }
        })

        this.total = res.data.total
        this.loading = false
      }
    },
    //新增算法
    addArithmetic() {
      this.$router.push({
        name: 'addArithmetic'
      })
    },
    //编辑
    editClick(row) {
      this.$router.push({
        name: 'addArithmetic',
        query: {
          id: row.id,
          pageNum: this.queryInfo.pageNum
        }
      })
    },
    //启用禁用
    notState(row) {
      const query = {
        id: row.id,
        value: row.isEnable
      }
      this.$alert('您是否确认操作？')
        .then(function () {
          return updateEnable(query)
        })
        .then(() => {
          this.search()
          this.$message.success('操作成功')
        })
        .catch(() => {
          this.search()
        })
    },
    //冻结激活
    accoutClick(row) {
      const query = {
        id: row.id,
        value: !row.isFreeze
      }
      this.$alert('您是否确认操作？')
        .then(function () {
          return updateFreeze(query)
        })
        .then(() => {
          this.search()
          this.$message.success('操作成功')
        })
        .catch(() => {})
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'createTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orderByParam = 'c.create_time asc'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orderByParam = 'c.create_time desc'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orderByParam = 'c.create_time desc'
          this.search()
        }
      }
    },

    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  height: calc(100vh - 150px);
  overflow: auto;
}
.seach_box {
  display: flex;
  margin: 15px 0;

  .input-with-select {
    width: 400px;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}
.office {
  display: inline-block;
  background: #edf4ff;
  border: 1px solid #559eff;
  color: #4e93fb;
  border-radius: 2px;
  font-size: 12px;
  padding: 0px 3px;
}
.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  overflow: hidden;

  .input-with-select {
    width: 400px;
    float: right;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

.in_table {
  width: 100%;
  margin-top: 20px;

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}
.edit {
  padding-left: 20px;
  color: #4e93fb;
  cursor: pointer;
}
.frozen {
  padding-left: 20px;
  color: #fd5469;
  cursor: pointer;
}

.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
.dialogVisible {
  padding-left: 30px;
  .dialogVisible_top {
    .username_phone {
      padding-bottom: 10px;
      font-size: 18px;
      color: #212121;
      font-weight: 550;
      .feed_center {
        margin-left: 10px;
        margin-right: 10px;
        position: relative;
        top: 2px;
        display: inline-block;
        width: 0px;
        height: 14.5px;
        border: 2px solid #d4d4d4;
      }
    }
  }
  .feed_info {
    padding-top: 10px;
    .feed_info_cont {
      .caption {
        width: 340px;
        padding: 10px 0px;
        color: #333333;
      }
      .answer {
        padding: 5px 0px;
      }
      .answer_type {
        width: 510px;
        padding: 15px;
        min-height: 60px;
        background: #f9f9f9;
        border-radius: 2px;
      }
      .answer_center {
        padding-top: 5px;
        display: flex;
        flex-direction: column;
      }
      .answer_phone {
        width: 541px;
        padding-left: 20px;
        height: 40px;
        background: #f9f9f9;
        border-radius: 2px;
        line-height: 40px;
      }
    }
  }
}
</style>
